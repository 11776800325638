import React from "react";
import Layout from "../components/layout"
import {graphql, useStaticQuery, Link } from "gatsby"
import References from "../components/references"
import './horsegrid.css';

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

function ReferenceFoals ({data}) {
  const horseArrRefFoals = data.allMarkdownRemark.edges.map(horses =>{
    return horses.node
  })

    return (
      <>
      <title>EC Horses </title>
       <Layout>
          <h1 className="page-title"> REFERENCES FOALS</h1>
          <div className="references-cards">
            {horseArrRefFoals.map((horse) => {
              const image = getImage(horse.frontmatter.img)
              const bgImage = convertToBgImage(image)
              return (
                <>
                  <BgImage
                    image={image}
                    style= {{minWidth: 105,
                     maxHeight:505,
                     backgroundPosition: "center",
                      backgrounSize:"contain"}}
                    id="horse-card"
                  >

                  <References
                    name={horse.frontmatter.title}
                    birth={horse.frontmatter.birth}
                    breeding={horse.frontmatter.breeding}
                    gender={horse.frontmatter.gender}
                    representing={horse.frontmatter.representing}
                  />
                  </BgImage>
                </>
              )
            })}
          </div>

       </Layout>
      </>
    );
};

export default ReferenceFoals;

export const AllReferencesFoalsQuery = graphql`
  query ReferencesFoalsquery {
    allMarkdownRemark(filter: {frontmatter: {class: {eq: "REFERENCES FOALS"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            class
            birth
            gender
            breeding
            representing
            img {
              childImageSharp {
                gatsbyImageData(layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`
